<template>
  <v-app>
    <v-main>
      <v-container class="grey lighten-5">
        <v-row justify="center" no-gutters>
          <v-col class="primary lighten-2 py-4 text-center white--text" cols="12">
          </v-col>
        </v-row>
        <router-view />

      </v-container> 
      <v-footer color="primary lighten-1" padless>
        <v-row justify="center" no-gutters>
          <v-col class="primary lighten-2 py-4 text-center white--text" cols="12">
            Made by <a style="color: #fff" href="https://codelabi.com" target="_blank"><strong>Codelabi.com</strong></a>
            {{ new Date().getFullYear() }}
          </v-col>
        </v-row>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
</script>
